import React from 'react'
import OutLineSideBar from '../../newComponent/outline/OutLineSideBar'
import EpisodeList from '../../newComponent/manage/EpisodeList'
import CardComponent from '../../newComponent/mypage/CardComponent'
// import { Follow, More, Alarm, ManageBlack } from '../../asset/icon'

const NewEpisodeManagement = () => {
  return (
    <div className="flex h-screen overflow-y-auto bg-[#6B6F87]">
      <OutLineSideBar />
      <main className="flex-grow min-w-[1000px] flex justify-start items-start pt-8 pr-8 pb-8">
        <div className="flex w-full max-w-6xl p-5 bg-[#F7F8FC] rounded-2xl shadow-md mb-5 min-h-[90vh]">
          <div className="basis-3/5 h-full overflow-y-auto">
            <EpisodeList />
          </div>
          <div className="w-[2px] bg-gray-300 mx-5 hidden md:block"></div>
          <div className="basis-2/5 flex flex-col mt-5 md:mt-0">
            <CardComponent />
            {/*<div className="bg-white p-4 rounded-lg shadow-md mt-4 overflow-y-auto">*/}
            {/*  <h2 className="text-xl font-bold mb-4">미리보기</h2>*/}
            {/*  <p className="text-gray-600 mb-4">*/}
            {/*    아이콘 클릭시 해당 미리보기 팝업창으로 변경 사항 확인이*/}
            {/*    가능합니다.*/}
            {/*  </p>*/}
            {/*  <div className="flex items-center mb-4">*/}
            {/*    <img*/}
            {/*      src="https://via.placeholder.com/50"*/}
            {/*      alt="Profile"*/}
            {/*      className="w-24 h-24 rounded-full mr-4"*/}
            {/*    />*/}
            {/*    <div>*/}
            {/*      <h3 className="text-lg font-bold">세경이의 하루아채</h3>*/}
            {/*      <p className="text-gray-600">세경이</p>*/}
            {/*      <div className="flex items-center space-x-2 mt-4">*/}
            {/*        <img src={Follow} alt="Follow" className="text-gray-600" />*/}
            {/*        <img*/}
            {/*          src={Alarm}*/}
            {/*          alt="Alarm"*/}
            {/*          className="w-6 h-6 text-gray-600"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*          src={More}*/}
            {/*          alt="More"*/}
            {/*          className="w-6 h-6 text-gray-600"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*          src={ManageBlack}*/}
            {/*          alt="Settings"*/}
            {/*          className="w-6 h-6 text-gray-600"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <p className="text-gray-600 mb-4">*/}
            {/*    세경이의 하루아채는 매일 아침 하우스를과 함께 그려나가며 여러*/}
            {/*    가지 에피소드와 즐거움을 담아냅니다.*/}
            {/*  </p>*/}
            {/*  <p className="text-gray-600 mb-4">이메일: sibalsekki@naver.com</p>*/}
            {/*  <hr className="mb-4" />*/}
            {/*  <p className="text-gray-600 mb-4">*/}
            {/*    연동 플랫폼 팟캐스트 앱에서 변경 사항을 확인하는 데 24시간이*/}
            {/*    걸릴 수 있습니다.*/}
            {/*  </p>*/}
            {/*  <div className="flex justify-between">*/}
            {/*    <button className="bg-gray-200 text-gray-700 py-2 px-4 rounded-full">*/}
            {/*      초기화*/}
            {/*    </button>*/}
            {/*    <button className="bg-blue-700 text-white py-2 px-4 rounded-full">*/}
            {/*      저장*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </main>
    </div>
  )
}

export default NewEpisodeManagement
