import React from 'react'
import OutLineSideBar from '../../newComponent/outline/OutLineSideBar'
import MyPageComponent from '../../newComponent/mypage/MyPageComponent'
import CardComponent from '../../newComponent/mypage/CardComponent'
import Preview from '../../newComponent/mypage/Preview'
// import { useNavigate } from 'react-router-dom'
// import Cookies from 'js-cookie'

const MyPage = () => {
  // const navigate = useNavigate()

  // const Logout = () => {
  //   const allCookies = Cookies.get()
  //   for (let cookieName in allCookies) {
  //     Cookies.remove(cookieName)
  //   }
  //   navigate('/')
  // }

  return (
    <div className="flex h-screen overflow-y-auto bg-[#6B6F87]">
      <OutLineSideBar />

      <main className="flex-grow min-w-[1000px] flex justify-start items-start pt-8 pr-8 pb-8">
        {/* 흰색 박스 */}
        <div className="flex w-full max-w-6xl p-5 bg-[#F7F8FC] rounded-2xl shadow-md min-h-[90vh]">
          <div className="basis-2/3">
            <MyPageComponent />
          </div>

          {/* 구분선 */}
          <div className="w-[1px] bg-gray-300 mx-3"></div>

          {/* 오른쪽 콘텐츠 */}
          <div className="basis-1/3 flex flex-col">
            <div className="w-full p-5 space-y-6">
              {/*<div className="flex justify-end">*/}
              {/*  <button*/}
              {/*    onClick={Logout}*/}
              {/*    className="bg-blue-500 text-white py-2 px-4 rounded"*/}
              {/*  >*/}
              {/*    로그아웃*/}
              {/*  </button>*/}
              {/*</div>*/}
              <CardComponent />
              <Preview />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default MyPage
