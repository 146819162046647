import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Hertz,
  Page,
  Create,
  Revenue,
  Manage,
  Advertise,
  Chart,
  LinkIcon,
  Slogan,
} from '../../asset/icon'
import PropTypes from 'prop-types'

const OutLineSideBar = () => {
  const [activeItem, setActiveItem] = useState('')
  const navigate = useNavigate()

  // 페이지가 로드될 때 localStorage에서 활성화된 메뉴를 불러옴
  useEffect(() => {
    const savedActiveItem = localStorage.getItem('activeItem')
    if (savedActiveItem) {
      setActiveItem(savedActiveItem)
    }
  }, [])

  // 메뉴를 클릭할 때 상태와 localStorage에 저장
  const handleItemClick = (text, to) => {
    setActiveItem(text)
    localStorage.setItem('activeItem', text) // localStorage에 저장
    navigate(to) // 페이지 이동
  }

  return (
    <div className="bg-[#6B6F87] text-white pt-5 pr-3 flex flex-col items-start h-full min-w-[250px]">
      {/* 로고 */}
      <div className="pl-5 mb-10 mt-5">
        <div
          className="flex items-end cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img src={Hertz} alt="Hertz Icon" />
          <img src={Slogan} alt="Hertz Slogan" className="ml-3" />
        </div>
      </div>

      {/* 메뉴 리스트 */}
      <ul className="list-none p-0 w-full space-y-5">
        <SidebarItem
          to="/NewMyPage"
          icon={Page}
          text="마이페이지"
          isActive={activeItem === '마이페이지'}
          onClick={() => handleItemClick('마이페이지', '/NewMyPage')}
        />
        <SidebarItem
          to="/NewEpisodeCreate_v1"
          icon={Create}
          text="에피소드 만들기"
          isActive={activeItem === '에피소드 만들기'}
          onClick={() =>
            handleItemClick('에피소드 만들기', '/NewEpisodeCreate_v1')
          }
        />
        <SidebarItem
          to="/NewEpisodeManagement"
          icon={Manage}
          text="에피소드 관리하기"
          isActive={activeItem === '에피소드 관리하기'}
          onClick={() =>
            handleItemClick('에피소드 관리하기', '/NewEpisodeManagement')
          }
        />
        <SidebarItem
          to="/NewAdList"
          icon={Advertise}
          text="광고"
          isActive={activeItem === '광고'}
          onClick={() => handleItemClick('광고', '/NewAdList')}
        />
        <SidebarItem
          to="/NewMyPage"
          icon={Chart}
          text="에피소드 통계"
          isActive={activeItem === '에피소드 통계'}
          onClick={() => handleItemClick('에피소드 통계', '/NewMyPage')}
        />
        <SidebarItem
          to="/NewMyPage"
          icon={Revenue}
          text="수익"
          isActive={activeItem === '수익'}
          onClick={() => handleItemClick('수익', '/NewMyPage')}
        />
        <SidebarItem
          to="/Link"
          icon={LinkIcon}
          text="연동"
          isActive={activeItem === '연동'}
          onClick={() => handleItemClick('연동', '/Link')}
        />
      </ul>
    </div>
  )
}

// Sidebar 메뉴 아이템 컴포넌트
const SidebarItem = ({ icon, text, isActive, onClick }) => (
  <li
    className={`flex items-center w-full overflow-hidden cursor-pointer ${
      isActive ? 'bg-[#9498AE]' : ''
    }  py-1`} // padding과 border-radius 설정
    onClick={onClick}
    style={{ borderTopRightRadius: '50px', borderBottomRightRadius: '50px' }}
  >
    <div className="ml-5" />
    <img src={icon} alt={`${text} Icon`} className="mr-2 flex-shrink-0" />
    <span className="text-white text-lg no-underline hover:underline flex-grow truncate">
      {text}
    </span>
  </li>
)

// PropTypes 설정
SidebarItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default OutLineSideBar
