import React from 'react'
import HeroSection from '../../newComponent/mainpage/HeroSection'
import FeaturesSection from '../../newComponent/mainpage/FeaturesSection'
import MainContent from '../../newComponent/mainpage/MainContent'
import SubContent from '../../newComponent/mainpage/SubContent'
import Footer from '../../newComponent/mainpage/Footer'

function MainPage() {
  return (
    <div className="overflow-y-auto">
      {/*<Header />*/}
      <HeroSection />
      <FeaturesSection />
      <MainContent />
      <SubContent />
      <Footer />
    </div>
  )
}

export default MainPage
