import React from 'react'
import LoginComponent from './LoginComponent'
import OutLineSideBarClean from '../../newComponent/outline/OutLineSideBarClean'

const Login = () => {
  return (
    <div className="flex h-screen overflow-y-auto bg-[#6B6F87]">
      <OutLineSideBarClean />
      <main className="flex-grow min-w-[1000px] flex justify-start items-start pt-8 pr-8 pb-8">
        <div className="flex w-full max-w-6xl p-5 bg-[#F7F8FC] rounded-2xl shadow-md mb-5 min-h-[90vh]">
          <div className="basis-2/3">
            <LoginComponent />
          </div>
          <div className="w-[1px] bg-gray-300 mx-3"></div>
          <div className="basis-1/3 flex flex-col"></div>
        </div>
      </main>
    </div>
  )
}

export default Login
