import React, { useState } from 'react'
import {
  ApplePod,
  GooglePod,
  GooglePreview,
  ApplePreview,
  SpotifyPreview,
  SpotifyPod,
} from '../../asset/icon'

const images = [GooglePreview, ApplePreview, SpotifyPreview]

const Preview = () => {
  const [previewImg, setPreviewImg] = useState(images[0])

  const handlePreview = (e) => {
    setPreviewImg(images[e])
  }
  return (
    <div className="flex justify-end items-center mb-5 w-full">
      <div className="flex flex-col items-center bg-white p-6 rounded-[12px] shadow-md w-full max-w-md">
        {/* 상단 아이콘과 타이틀 */}
        <div className="w-full flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">미리보기</h2>
          <div className="flex space-x-2">
            <img
              src={GooglePod}
              alt="Google Icon"
              className="h-6 hover:cursor-pointer"
              onClick={() => handlePreview('0')}
            />
            <img
              src={ApplePod}
              alt="Apple Icon"
              className="h-6 hover:cursor-pointer"
              onClick={() => handlePreview('1')}
            />
            <img
              src={SpotifyPod}
              alt="Spotify Icon"
              className="h-6 hover:cursor-pointer"
              onClick={() => handlePreview('2')}
            />
          </div>
        </div>

        {/* 설명 */}
        <p className="text-sm text-gray-600 mb-2">
          아이콘 클릭시 해당 미리보기 팝업창으로 변경 사항 확인이 가능합니다.
        </p>

        <hr className="w-full border-Gray/gray400 my-4" />

        {/* 이미지 및 미리보기 내용 */}
        <div className="w-full bg-black rounded-lg p-4">
          <img src={previewImg} alt="GooglePreview" />
        </div>

        {/* 이미지 아래 구분선 */}
        <hr className="w-full border-Gray/gray400 my-4" />

        {/* 하단 설명과 버튼 */}
        <p className="text-sm text-gray-600 mt-4">
          연동 플랫폼 팟캐스트 앱에서 변경 사항을 확인하는 데 24시간이 걸릴 수
          있습니다.
        </p>
        {/* 초기화 및 저장 버튼 */}
        <div className="flex justify-between w-full space-x-2 mt-2">
          <button className="flex-1 border border-gray-400 py-2 rounded text-gray-700">
            초기화
          </button>
          <button className="flex-1 bg-gray-600 text-white py-2 rounded">
            저장
          </button>
        </div>
      </div>
    </div>
  )
}

export default Preview
