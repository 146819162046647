import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import OutLineSideBar from '../../newComponent/outline/OutLineSideBar'
import EpisodeCreateForm_v1 from '../../newComponent/create/EpisodeCreateForm_v1'
import CardComponent from '../../newComponent/mypage/CardComponent'

const NewEpisodeCreate_v1 = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage = 'Are you sure you want to leave this page?'
      e.returnValue = confirmationMessage // 이 메시지는 일부 브라우저에서만 표시됩니다.
      return confirmationMessage
    }

    const handlePopState = (e) => {
      if (!window.confirm('Are you sure you want to leave this page?')) {
        navigate(location.pathname) // 현재 경로로 다시 이동
        e.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [navigate, location])

  return (
    <div className="flex h-screen overflow-y-auto bg-[#6B6F87]">
      <OutLineSideBar />
      <main className="flex-grow min-w-[1000px] flex justify-start items-start pt-8 pr-8 pb-8">
        <div className="flex w-full max-w-6xl p-5 bg-[#F7F8FC] rounded-2xl shadow-md mb-5 min-h-[90vh]">
          <div className="basis-2/3">
            <EpisodeCreateForm_v1 />
          </div>
          <div className="w-[1px] bg-gray-300 mx-3"></div>
          <div className="basis-1/3 flex flex-col">
            <div className="w-full p-5 space-y-6">
              <CardComponent />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default NewEpisodeCreate_v1
