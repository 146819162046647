import React from 'react'
import { Hertz } from '../../asset/icon'
import { Link } from 'react-router-dom'

const OutLineSideBarClean = () => {
  return (
    <div className="bg-[#6B6F87] text-white pt-5 pl-5 flex flex-col items-start h-full min-w-[250px]">
      <div className="mb-10">
        <Link to="/" className="flex items-end">
          <img src={Hertz} alt="Hertz Icon" className="cursor-pointer" />
        </Link>
      </div>
    </div>
  )
}

export default OutLineSideBarClean
