import React from 'react'
import { MainBG, MainLogo, MainWave } from '../../asset/icon'
import { useNavigate } from 'react-router-dom'

const HeroSection = () => {
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate('/Login')
  }

  return (
    <div
      className="relative bg-cover bg-center"
      style={{ backgroundImage: `url(${MainBG})` }}
    >
      {/* 어두운 오버레이 */}
      <div className="absolute inset-0 bg-black opacity-50" />

      {/* 상단 네비게이션 */}
      <div className="flex justify-between items-center p-4 relative z-10">
        <div className="text-xl font-bold">
          <img src={MainLogo} alt="Logo" className="h-8" />
        </div>
        <div className="flex space-x-8 items-center">
          <a href="#" className="text-white hover:text-gray-900">
            회사소개
          </a>
          <a href="#" className="text-white hover:text-gray-900">
            서비스 소개
          </a>
          <a href="#" className="text-white hover:text-gray-900">
            고객지원
          </a>
          <a href="#" className="text-white hover:text-gray-900">
            문의
          </a>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>

      {/* 메인 콘텐츠 */}
      <div className="relative z-10 flex flex-col items-center justify-center text-white text-center">
        <img alt="Logo" src={MainWave} className="h-60" />
        <p className="text-8xl font-bold leading-tight">
          START <br /> PODCASTING
        </p>
        <p className="mt-4 text-lg">
          나를 모두에게 대한민국 팟캐스트 호스팅 서비스 아이엠이 <br />
          다채로운 이야기를 들려줄 여러분들을 찾습니다.
        </p>

        {/* 사전 신청 버튼 */}
        <button className="mt-6 bg-blue-500 px-6 py-3 rounded text-lg mb-5">
          사전신청
        </button>
      </div>
    </div>
  )
}

export default HeroSection
